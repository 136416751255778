import * as React from "react";
import { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { useLocation } from "@reach/router";

import ContactForm from "./ContactForm";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import btnClose from "../assets/images/close-modal@3x.png";

const Header = ({ siteTitle }) => {

  const [navbarOpen, setNavbarOpen] = useState(false)
  const [showContact, setshowContact] = useState(false)
  
  const { pathname } = useLocation();
  
  const navbarStyle = (
    pathname === '/timeline' || pathname === '/timeline/' || 
    pathname === '/intervieweds' || pathname === '/intervieweds/' ||
    pathname === '/downloadable' || pathname === '/downloadable/')
    ? 'light'
    : 'dark';

  useEffect(() => {  

  }, [])

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen)
  }

  const handleMenuMobile = () => {
    setNavbarOpen(!navbarOpen)
  }

  return (
    <>
      <div className={`${showContact ? 'modal fade d-block show' : 'modal fade'}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-contact animate__animated animate__fadeInUp">
          <div className="modal-content">
            <div className="modal-body">
              <div className="btn-close" onClick={() => setshowContact(false)}>
                <img src={btnClose} alt="button close" />
              </div>
              <ContactForm setshowContact={setshowContact} />
            </div>
          </div>
        </div>
      </div>
    
      <nav className={`navbar navbar-expand-xl navbar-${navbarStyle} bg-transparent main-navbar`}>
        <div className="container-fluid">
          <div className="w-100 d-flex justify-content-between">
            <Link className="navbar-brand" to="/">Educando<br />en Pandemia</Link>
            <button className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={toggleNavbar}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            <div className={ `${ navbarOpen ? 'open' : '' } menu-wrapper ` } id="navbarNav">
              <div className="mobile-menu-head d-flex d-md-none">
                <a className="navbar-brand" href="/">Educando<br />en Pandemia</a>
                <button 
                  type="button" 
                  className="navbar-toggler text-dark"
                  onClick={ handleMenuMobile }
                  >
                  <FontAwesomeIcon icon={faBars} />
                </button>
              </div>
              
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" activeClassName="active" to="/context">Contexto</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" activeClassName="active" to="/intervieweds">Entrevistados</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" activeClassName="active" to="/timeline">Línea de tiempo</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" activeClassName="active" to="/about">About</Link>
                </li>
              </ul>

              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" activeClassName="active" to="/downloadable">Descargables</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" target="_blank" href="https://open.spotify.com/show/5AEGHgto2pcWIUvllkonrj?si=eeba97b8b5b04be3">Spotify</a>
                </li>
                <li className="nav-item">
                  <a 
                    className="nav-link" 
                    type="button"
                    onClick={() => { setshowContact(true) }}>Contacto</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
