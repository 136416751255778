import React, { useState } from 'react';
import { useFormik } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const functionURL = "https://sangria-gazelle-5368.twil.io/send-email";

const validate = values => {
  const errors = {};
  if (!values.body) {
    errors.body = 'El campo es requerido';
  } 
  if (!values.fromEmail) {
    errors.fromEmail = 'El campo es requerido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.fromEmail)) {
    errors.fromEmail = 'Debe ingresar un correo electrónico válido';
  }

  if (!values.fromName) {
    errors.fromName = 'El campo es requerido';
  }
  return errors;
};

const ContactForm = ({ setshowContact }) => {

  const [contactSent, setContactSent] = useState(false);

  const formik = useFormik({
    initialValues: {
      body: '',
      fromEmail: '',
      fromName: ''
    },
    validate,
    onSubmit: async (values, { resetForm }) => {

      const response = await fetch(functionURL, {
        method: "post",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        // body: JSON.stringify(values),
        body: new URLSearchParams(values).toString(),
      });
      
      if (response.status === 200) {
        resetForm();
        setContactSent(true);
        setTimeout(() => {
          setshowContact(false);
          setContactSent(false);
        }, 3000);
      } else {
        alert('Hubo un error');
      }

    }
  });

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-9 pt-4">
        <h1 className="fw-normal text-center">¿Tienes algo que contarnos?</h1>

        {
          contactSent ?
            <div className="alert alert-success animate__animated animate__fadeIn" role="alert">
              <p className="m-0"><FontAwesomeIcon icon={faCheck} className="me-2" /> Tu mensaje ha sido enviado correctamente.</p>
            </div>
            : null
        }

        <form 
          onSubmit={formik.handleSubmit} 
          method="post">
          <div className="mb-3">
            <label 
              className="form-label"
              htmlFor="body">
                Déjanos tu testimonio acá.
            </label>
            <textarea 
              rows="3" 
              placeholder="Testimonio"
              className="form-control" 
              id="body"
              name="body"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.body}
              ></textarea>
              { formik.touched.body && formik.errors.body ? <div id="emailHelp" className="form-text text-danger">{ formik.errors.body }</div> : null }
          </div>
          <div className="mb-3">
            <input 
              type="email" 
              className="form-control" 
              placeholder="Correo electrónico" 
              id="fromEmail" 
              name="fromEmail"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fromEmail}
              />
              { formik.touched.fromEmail && formik.errors.fromEmail ? <div id="emailHelp" className="form-text text-danger">{ formik.errors.fromEmail }</div> : null }
          </div>
          <div className="mb-3">
            <input 
              type="name" 
              className="form-control" 
              placeholder="Nombre y apellido"
              id="fromName"
              name="fromName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fromName}
              />
              { formik.touched.fromName && formik.errors.fromName ? <div id="emailHelp" className="form-text text-danger">{ formik.errors.fromName }</div> : null }
          </div>
          <button 
            type="submit" 
            disabled={formik.isSubmitting}
            className="btn btn-primary btn-cmp w-100"
            >
              Enviar
            </button>
        </form>
      </div>
    </div>
  )
}

export default ContactForm